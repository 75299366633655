<template>
        <defaultSec :title="'退款列表'">
            <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="退款单号">
                    <el-input v-model="formParam.refundNo" placeholder="请输入退款单号" clearable></el-input>
                </el-form-item>
                <el-form-item label="子单号">
                    <el-input v-model="formParam.orderNo" placeholder="请输入子单号" clearable></el-input>
                </el-form-item>
                <el-form-item label="退款状态">
                    <el-select v-model="formParam.refundStatus" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已申请" value="1"></el-option>
                        <el-option label="已审核" value="2"></el-option>
                        <el-option label="已拒绝" value="3"></el-option>
                        <el-option label="已完成" value="4"></el-option>
                        <el-option label="已取消" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="平台">
                    <el-select v-model="formParam.dataPlatform" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="微信" value="wx_mall"></el-option>
                        <el-option label="淘宝" value="taobao_mall"></el-option>
                        <el-option label="京东" value="jingdong_mall"></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item>
                    <el-checkbox v-model="formParam.mine" checked true-label="1" false-label="0" >我的</el-checkbox>
                </el-form-item>-->
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
<!--                    <el-button type="danger" @click="refundMethod">退款</el-button>-->
                </el-form-item>
                <el-form-item>
                    <el-switch
                        v-model="formParam.mine"
                        active-value="0"
                        active-text="全部"
                        inactive-value="1"
                        inactive-text="我的"
                        @change="initList">
                    </el-switch>
                </el-form-item>
            </el-form>
            </keep-alive>
            <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column type="expand" label="详情" width="70">
                        <template slot-scope="scope">
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">主单号:</el-col>
                                <el-col :span="2" class="expand-content">
                                    {{scope.row.orderMainNo}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">申请时间:</el-col>
                                <el-col :span="2" class="expand-content">
                                    {{scope.row.createTime}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">申请人:</el-col>
                                <el-col :span="2" class="expand-content">
                                    {{scope.row.createId}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">审核时间:</el-col>
                                <el-col :span="2" class="expand-content">
                                    {{scope.row.updateTime}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">审核人:</el-col>
                                <el-col :span="2" class="expand-content">
                                    {{scope.row.updateId}}
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundNo" label="退款单号"></el-table-column>
                    <el-table-column prop="orderNo" label="子单号"></el-table-column>
                    <el-table-column prop="refundStatus" label="退款状态">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="" v-if="scope.row.refundStatus==1">退款申请</el-tag>
                            <el-tag size="medium" type="danger" v-if="scope.row.refundStatus==2">退款中</el-tag>
                            <el-tag size="medium" type="info" v-if="scope.row.refundStatus==3">拒绝退款</el-tag>
                            <el-tag size="medium" type="success" v-if="scope.row.refundStatus==4">退款完成</el-tag>
                            <el-tag size="medium" type="warning" v-if="scope.row.refundStatus==5">取消退款</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundAmountApply" label="申请退款金额"></el-table-column>
                    <el-table-column prop="refundAmountCheck" label="审批退款金额"></el-table-column>
                    <el-table-column prop="refundType" label="售后类型">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="danger" v-if="scope.row.refundType==1">退款</el-tag>
                            <el-tag size="medium" type="warning" v-if="scope.row.refundType==2">退货退款</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dataPlatform" label="平台">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="danger" v-if="scope.row.dataPlatform=='wx_mall'">微信</el-tag>
                            <el-tag size="medium" type="warning" v-if="scope.row.dataPlatform=='taobao_mall'">淘宝</el-tag>
                            <el-tag size="medium" type="warning" v-if="scope.row.dataPlatform=='jingdong_mall'">京东</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundReason" label="退款原因"></el-table-column>
                    <el-table-column prop="logisticsName" label="物流名称">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.logisticsName" >
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="logisticsNo" label="物流单号">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.logisticsNo" >
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width="140">
                        <template slot-scope="scope">
                            <el-button-group>
                                <el-button type="success" plain size="mini" @click="toDetail(scope.row.refundNo,scope.row.orderNo,2)">查看</el-button>
<!--                                <el-button type="warning " size="mini" v-if="scope.row.dataPlatform == 'wx_mall'" @click="updateLogisticsInfo(scope.row)">修改</el-button>-->
                            </el-button-group>
<!--                            <el-button-group>-->
<!--                                <el-button type="primary" v-if="scope.row.dataPlatform == 'wx_mall' && scope.row.refundStatus==1" plain size="mini" @click="toCheck(scope.row.refundNo,scope.row.orderNo,3)">审批</el-button>-->
<!--                                <el-button type="danger" v-if="scope.row.dataPlatform == 'wx_mall' && scope.row.refundStatus == 1" plain size="mini" @click="toCancel(scope.row.refundNo,scope.row.orderNo,3)">取消</el-button>-->
<!--                            </el-button-group>-->
                        </template>
                    </el-table-column>
                </el-table>
            </tablePagination>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    refundNo:'',
                    orderNo:'',
                    refundStatus:'',
                    dataPlatform:'',
                    pageType:2,
                    mine:"0"
                },
                tableList:[],
                total:0
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                this.$apiGet('lechun-csms/refundApi/listRefundInfoPage',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list

                })
            },
            refundMethod(){
                this.$apiGet('lechun-csms/refundApi/refundPay',this.formParam).then(res => {
                    console.log(res)
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },

            toDetail(refundNo,orderNo,pageType){
                this.$router.push({path:'/refundsList/:refundNo/:orderNo/:pageType',name:'refundsDetail',params:{refundNo:refundNo,orderNo:orderNo,pageType:pageType}})
            },

            // 审核退款
            toCheck(refundNo,orderNo,pageType){
                this.$router.push({path:'/refundsList/:refundNo/:orderNo/:pageType',name:'refundsDetail',params:{refundNo:refundNo,orderNo:orderNo,pageType:pageType}})
            },

            // 更新物流信息
            updateLogisticsInfo(row){
                console.log(row)
                if(row.logisticsName == null && row.logisticsNo == null){
                    alert("物流名称和单号无内容");
                    return;
                }
                this.$apiGet('lechun-csms/refundApi/updateLogisticsInfo',{refundNo:row.refundNo,logisticsName:row.logisticsName,logisticsNo:row.logisticsNo}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.initList();
                })
            },
            // 取消退款
            toCancel(refundNo,orderNo,pageType){
                // 调用后台方法取消方法
                this.$apiGet('lechun-csms/refundApi/refundCancel',{refundNo:refundNo,orderNo:orderNo,pageType:pageType}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.initList();
                })
            },

            formatRefundType:function(row, column){
                if(row.refundType == 1){
                 return  "退款";
                }
                if(row.refundType == 2){
                 return  "退货退款";
                }
            }
        }
    }
</script>


<style scoped>
        .expand-title{
            width: 70px;
            color: #444548;
            line-height: 30px;
            text-align: right;
            margin-right: 10px;
            font-weight: 600;
        }
        .expand-flex{
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }
</style>